<template>
  <div></div>
</template>
<script>
import * as Utils from "@/utils/Utils";
import { GOCARDLESS_SEND_SUCCESS_REQUEST } from "@/store/actionsType";

export default {
  data() {
    return {
      RFID: "",
    };
  },
  computed: {},
  mounted() {
    var q = this.$route.query.redirect_flow_id;
    this.RFID = q;
   // console.log("SQ", q);

    var planId = localStorage.getItem("1lQam60");
    var SessionToken = localStorage.getItem("3iXze89");
    var IsReccural = localStorage.getItem("8rKon25");
    var request = {
      jcrnsl: planId,
      Klvrwq: SessionToken,
      pxrvbw: this.RFID,
      wcsmgz: IsReccural,
    };
    this.$root.$refs.AppCyrus.start();
    this.$store
      .dispatch(GOCARDLESS_SEND_SUCCESS_REQUEST, request)
      .then(() => {
        this.$root.$refs.AppCyrus.finish();
       //console.log('Session.has("currenCompanyId")',Session.has("currenCompanyId"))
        localStorage.removeItem("1lQam60");
        localStorage.removeItem("3iXze89");
        localStorage.removeItem("8rKon25");
        Utils.toastSuccess("Subscription upgrade successful!");

        setTimeout(function () {
          Utils.toastSuccess("Redirecting to you main site.");
          
          setTimeout(function () { window.close(); }, 5000);
        }, 5000);
        
      })
      .catch(() => {
        this.$root.$refs.AppCyrus.fail();
      });
  },
  beforeDestroy() {},
  methods: {
    async sendRequestToServer() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(GOCARDLESS_SEND_SUCCESS_REQUEST, this.RFID)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
        //  console.log("serverResponse", resp);
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
  },
};
</script>
